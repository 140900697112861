import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { convertTime, generateTimes } from "../../../../../utils";
import { useFormikContext } from "formik";

const TimePickers = ({ index }) => {
  const { values, setFieldValue } = useFormikContext();
  const [startTime, setStartTime] = React.useState(
    values.daysAvailable[index].startTimeFormal
  );
  const [endTime, setEndTime] = React.useState(
    values.daysAvailable[index].endTimeFormal
  );

  const handleStart = (event) => {
    setFieldValue(
      `daysAvailable[${index}].startTime`,
      convertTime(event.target.value)
    );

    setFieldValue(
      `daysAvailable[${index}].startTimeFormal`,
      event.target.value
    );
    setStartTime(event.target.value);
  };

  const handleEnd = (event) => {
    setFieldValue(
      `daysAvailable[${index}].endTime`,
      convertTime(event.target.value)
    );
    setFieldValue(`daysAvailable[${index}].endTimeFormal`, event.target.value);
    setEndTime(event.target.value);
  };

  return (
    <Box flex={3} display="flex" justifyContent="space-between">
      <FormControl sx={{ width: "49%" }}>
        <InputLabel>Start Time</InputLabel>
        <Select
          value={startTime}
          onChange={handleStart}
          label="Start Time"
          inputProps={{ IconComponent: () => null }} // this part
          endAdornment={<AccessAlarmIcon />}
        >
          {generateTimes().map((time, index) => (
            <MenuItem key={index} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: "49%" }}>
        <InputLabel>End Time</InputLabel>
        <Select
          value={endTime}
          onChange={handleEnd}
          label="End Time"
          inputProps={{ IconComponent: () => null }} // this part
          endAdornment={<AccessAlarmIcon />}
        >
          {generateTimes().map((time, index) => (
            <MenuItem key={index} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TimePickers;
