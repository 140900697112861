import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../../../common/CustomButton";
import axios from "axios";
import {
  dateLabel,
  datesFormatter,
  displayWeekday,
  setCalendarSuccess,
} from "../../../../../utils";

import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const gridProps = { xs: 12, md: 6 };

const LabelValue = ({ label, value }) => {
  return (
    <Grid item {...gridProps}>
      <Typography variant="h5" fontSize="14px">
        {label}:
      </Typography>
      <Typography variant="h4" fontSize="18px" fontWeight="600">
        {value}
      </Typography>
    </Grid>
  );
};

const Review = () => {
  const { values } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    calendarName,
    bookingType,
    daysAvailable,
    startDate,
    endDate,
    minimumDateRange,
    maximumDateRange,
    timeBeforeAvailableAgain,
    minTimeBeforeRental,
    maxTimeBeforeRental,
  } = values;

  console.log(values);

  const createTemplate = async () => {
    try {
      setIsLoading(true);

      const myHeaders = {
        CustomerId: "CA8500B6-888C-4F3F-B3C2-FF15B256F6D3",
        "x-api-key": "Test1234",
        "Content-Type": "application/json",
      };

      const rawData = {
        customerId: "CA8500B6-888C-4F3F-B3C2-FF15B256F6D3",
        calendarStart: "2024-02-25T02:15:24.648Z",
        calendarEnd: "2028-02-25T02:15:24.648Z",
        availableDaySettings: datesFormatter(daysAvailable),
        multipleRentalDaysAllowed: bookingType === "multiDay" ? true : false,
        minRentalDays: minimumDateRange,
        maxRentalDays: maximumDateRange,
        totalQuantity: 1,
        minQuantityPerCheckout: 1,
        maxQuantityPerCheckout: 1,
        minTimeBeforeRental,
        maxTimeBeforeRental,
        timeBeforeAvailableAgain: "00:00:00",
      };

      const response = await axios.post(
        "https://coldsnapapps.com/api/CalendarSettings",
        rawData,
        { headers: myHeaders }
      );

      if (response) {
        setIsLoading(false);
        navigate("/");
        setCalendarSuccess(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box>
      <Typography textAlign="center" mb="40px" variant="h3" fontWeight="800">
        Review
      </Typography>

      <Grid container spacing={4}>
        <LabelValue label="Calendar Name" value={calendarName} />
        <LabelValue
          label="Booking Type"
          value={
            bookingType === "singleDay"
              ? "Single Day Rentals"
              : "Multi-Day Rentals"
          }
        />
        <LabelValue label="Start Calendar In" value={dateLabel(startDate)} />
        <LabelValue label="End Calendar In" value={dateLabel(endDate)} />

        <LabelValue
          label="Minimum Days Available"
          value={dateLabel(minimumDateRange)}
        />
        <LabelValue
          label="Maximum Days Available"
          value={dateLabel(maximumDateRange)}
        />
        <LabelValue
          label="Time Until Next Use"
          value={dateLabel(timeBeforeAvailableAgain)}
        />
      </Grid>
      <Grid sx={{ marginTop: "40px" }} container spacing={4}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            textAlign="center"
            fontSize="18px"
            fontWeight={"600"}
          >
            Days Available:
          </Typography>
        </Grid>

        {daysAvailable.map(
          (day, index) =>
            day.selected && (
              <Grid key={index} item md={4}>
                <Typography fontWeight={"600"} key={day.day}>
                  {day.day}{" "}
                  <span style={{ color: "grey" }}>({displayWeekday(day)})</span>
                </Typography>
              </Grid>
            )
        )}
      </Grid>

      <Box display="flex" justifyContent="center" my="40px">
        <CustomButton onClick={() => createTemplate()} variant="contained">
          {isLoading ? (
            <>
              <span>Processing</span>
              <TailSpin height="30" width="60" color="white" />
            </>
          ) : (
            "Submit Calendar"
          )}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Review;
