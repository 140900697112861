import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
// import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, value, icon, difference }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" p="1.25rem">
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ color: colors.grey[100] }}
        >
          {title}
        </Typography>
        {icon}
      </Box>

      <Typography
        color={colors.secondary[500]}
        variant="h3"
        fontWeight="bold"
        sx={{ m: "8px 0px" }}
      >
        {value}
      </Typography>

      <Typography
        variant="h5"
        fontStyle="italic"
        fontSize="14px"
        sx={{ color: colors.grey[500] }}
      >
        {difference}
      </Typography>
    </Box>
  );
};

export default StatBox;
