import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const ArrowTooltips = ({ info }) => {
  return (
    <Tooltip title={<h2>{info}</h2>} arrow>
      <InfoOutlinedIcon color="primary" />
    </Tooltip>
  );
};

export default ArrowTooltips;
