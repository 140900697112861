import React from "react";
import { Grid, InputAdornment, Typography } from "@mui/material";
import FormikField from "../../../../FormikField";
import CustomAccordion from "../CustomAccordion";

const TimeBeforeAvailable = () => {
  return (
    <CustomAccordion title={"Time Until Next Use"}>
      <Grid container spacing={2}>
        <Grid sx={{ my: "20px" }} item xs={12}>
          <Typography textAlign="center" variant="h5" fontWeight="600">
            How long until the product is available for use again?
          </Typography>
          <Typography textAlign="center" variant="h6" fontWeight="600">
            (0 means same day)
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormikField
            name="timeBeforeAvailableAgain"
            isNumber={true}
            endAdornment={
              <InputAdornment position="end">day(s)</InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </CustomAccordion>
  );
};

export default TimeBeforeAvailable;
