import { ColorModeContext, useMode } from "./theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import Products from "./components/pages/Products";
import RentalHistory from "./components/pages/RentalHistory";
import Settings from "./components/pages/Settings";
import CreateCalendar from "./components/pages/CreateCalendar/CreateCalendar";
import Sidebar from "./components/Sidebar";
import Calendars from "./components/Calendars";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            {/* <Header /> */}
            <Box p={3}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/products" element={<Products />} />
                <Route path="/rentals" element={<RentalHistory />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/all-calendars" element={<Calendars />} />
                <Route path="/link-product" element={<CreateCalendar />} />
              </Routes>
            </Box>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
