import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { currencyFormatter } from "../utils";

const users = [
  {
    name: "Olivia Smart",
    email: "olivia.smart54@gmail.com",
    product: "Cake Table",
    cost: 120,
  },
  {
    name: "Jackson Lee",
    email: "jackson.lee@email.com",
    product: "Peg Board",
    cost: 142,
  },
  {
    name: "Isabella Nguyen",
    email: "isabella.nguyen@email.com",
    product: "Barbie Box",
    cost: 89,
  },
  {
    name: "William Kim",
    email: "will@email.com",
    product: "Wedding Backdrops",
    cost: 73,
  },
  {
    name: "Sofia Davis",
    email: "sofia.davis@email.com",
    product: "Birthday Backdrop",
    cost: 67,
  },
];

export default function AlignItemsList() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <List sx={{ width: "100%", mt: "10px" }}>
      {users.map((user, index) => (
        <React.Fragment key={index}>
          <ListItem alignItems="center">
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: colors.secondary[500] }}>
                {user.name[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h6" fontWeight="600">
                  {user.name}
                </Typography>
              }
              secondary={
                <Typography color={colors.grey[600]} variant="body2">
                  {user.email}
                </Typography>
              }
            />
            <Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="h5" fontWeight="700">
                  +{currencyFormatter.format(user.cost)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography color={colors.grey[600]} variant="body2">
                  {user.product}
                </Typography>
              </Box>
            </Box>
          </ListItem>
          {users.length - 1 !== index && (
            <Divider variant="inset" component="li" />
          )}
        </React.Fragment>
      ))}

      {/*       
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Summer BBQ"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                to Scott, Alex, Jennifer
              </Typography>
              {" — Wish I could come, but I'm out of town this…"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Oui Oui"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sandra Adams
              </Typography>
              {" — Do you have Paris recommendations? Have you ever…"}
            </React.Fragment>
          }
        />
      </ListItem> */}
    </List>
  );
}
