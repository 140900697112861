import React from "react";
import { Box, Collapse, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CustomAccordion = (props) => {
  const [checked, setChecked] = React.useState(false);
  const [isUp, setIsUp] = React.useState(true);

  const toggleIcon = () => {
    setIsUp(!isUp);
    setChecked((prev) => !prev);
  };

  const { title, children } = props;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          height: "70px",
        }}
        onClick={toggleIcon}
      >
        <Typography fontWeight="600" variant="h4">
          {title}
        </Typography>

        {isUp ? (
          <KeyboardArrowUpIcon fontSize="large" />
        ) : (
          <KeyboardArrowUpIcon
            fontSize="large"
            sx={{ transform: "rotate(180deg)" }}
          />
        )}
      </Box>
      <Collapse in={checked}>
        <Box width="95%" margin="auto">
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CustomAccordion;
