import { Box, InputAdornment, Typography } from "@mui/material";
import React from "react";
import FormikField from "../../../../FormikField";
import CustomAccordion from "../CustomAccordion";

const NumberOfDaysRange = () => {
  return (
    <CustomAccordion title={"# of Days Customers Can Book"}>
      {/* The length of time you allow someone to hold your product */}
      <Box
        display="flex"
        justifyContent="space-between"
        padding="15px"
        mb="30px"
      >
        <Box width={"45%"}>
          <Typography variant="h6" fontWeight={"600"}>
            Minimum:
          </Typography>
          <FormikField
            endAdornment={
              <InputAdornment position="end">day(s)</InputAdornment>
            }
            name="minimumDateRange"
            isNumber={true}
          />
        </Box>
        <Box width={"45%"}>
          <Typography variant="h6" fontWeight={"600"}>
            Maximum:
          </Typography>
          <FormikField
            endAdornment={
              <InputAdornment position="end">day(s)</InputAdornment>
            }
            name="maximumDateRange"
            isNumber={true}
          />
        </Box>
      </Box>
    </CustomAccordion>
  );
};

export default NumberOfDaysRange;
