import React from "react";
import { Form, useFormikContext } from "formik";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BasicInfo from "./Steps/BasicInfo/BasicInfo";
import Availability from "./Steps/Availability/Availability";
import { Paper, useTheme } from "@mui/material";
import WeekdaySelection from "./Steps/WeekdaySelection/WeekdaySelection";
import Review from "./Steps/Review/Review";
import { tokens } from "../../../theme";
import CustomButton from "../../../common/CustomButton";

const steps = [
  {
    label: "Basic Info",
    optional: false,
    form: <BasicInfo />,
  },
  {
    label: "Weekday Selection",
    optional: false,
    form: <WeekdaySelection />,
  },
  {
    label: "Availability",
    optional: false,
    form: <Availability />,
  },
  {
    label: "Review",
    optional: false,
    form: <Review />,
  },
  // {
  //   label: "Special Pricing",
  //   optional: true,
  //   form: <SpecialPricing />,
  // },
  // {
  //   label: "Payment Options",
  //   optional: true,
  //   form: <PaymentOptions />,
  // },
];

export const FormStepper = () => {
  const { values } = useFormikContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [activeStep, setActiveStep] = React.useState(0);

  const isStepOptional = (step) => step.optional;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(steps[activeStep])) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleDisable = (activeStep) => {
    switch (activeStep) {
      case 0:
        return Boolean(!values.calendarName);

      case 1:
        return values.daysAvailable.every((day) => !day.selected);

      default:
        return false;
    }
  };

  return (
    <Form>
      <Box sx={{ width: "100%" }}>
        <Stepper
          sx={{
            my: 5,
            "& .MuiStepLabel-root .Mui-completed": {
              color: colors.secondary[500], // circle color (COMPLETED)
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "red", // Just text label (COMPLETED)
              },
            "& .MuiStepLabel-root .Mui-active": {
              // color: "secondary.main", // circle color (ACTIVE)
              color: colors.secondary[600],
            },
            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
              color: "green", // Just text label (ACTIVE)
            },
            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
              fill: "white", // circle's number (ACTIVE)
            },
          }}
          activeStep={activeStep}
        >
          {steps.map((step) => {
            const labelProps = {};
            if (isStepOptional(step)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            return (
              <Step key={step.label}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          <Paper sx={{ p: 5, mx: "4rem" }} elevation={3}>
            {steps[activeStep].form}
          </Paper>
          <Box
            sx={{ display: "flex", flexDirection: "row", pt: 2, mx: "4rem" }}
          >
            <CustomButton
              variant="text"
              color="secondary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </CustomButton>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(steps[activeStep]) && (
              <Button
                style={{ fontSize: "1rem", padding: "5px 25px" }}
                variant="outlined"
                color="inherit"
                onClick={handleSkip}
                sx={{ mr: 1 }}
              >
                Skip
              </Button>
            )}

            {activeStep !== steps.length - 1 && (
              <CustomButton
                disabled={handleDisable(activeStep)}
                onClick={() => handleNext()}
                variant="contained"
                color="secondary"
              >
                Next
              </CustomButton>
            )}
          </Box>
        </React.Fragment>
      </Box>
    </Form>
  );
};
