import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = () => ({
  grey: {
    100: "#141414",
    200: "#292929",
    300: "#3d3d3d",
    400: "#525252",
    500: "#666666",
    600: "#858585",
    700: "#a3a3a3",
    800: "#c2c2c2",
    900: "#e0e0e0",
  },

  black: {
    100: "#040404",
    200: "#070707",
    300: "#0b0b0b",
    400: "#f2f0f0",
    500: "#121212",
    600: "#202020",
    700: "#717171",
    800: "#a0a0a0",
    900: "#d0d0d0",
  },

  secondary: {
    100: "#cceeff",
    200: "#99ddff",
    300: "#66cbfe",
    400: "#33bafe",
    500: "#00a9fe",
    600: "#0087cb",
    700: "#006598",
    800: "#004466",
    900: "#002233",
  },

  // purple
  primary: {
    100: "#ffddcc",
    200: "#ffbb99",
    300: "#fe9966",
    400: "#fe7733",
    500: "#fe5500",
    600: "#cb4400",
    700: "#983300",
    800: "#662200",
    900: "#331100",
  },

  // primary: {
  //   100: "#e7ccff",
  //   200: "#cf99ff",
  //   300: "#b766fe",
  //   400: "#9f33fe",
  //   500: "#8700fe",
  //   600: "#6c00cb",
  //   700: "#510098",
  //   800: "#360066",
  //   900: "#1b0033",
  // },

  // orange
  // secondary: {
  //   100: "#ffddcc",
  //   200: "#ffbb99",
  //   300: "#fe9966",
  //   400: "#fe7733",
  //   500: "#fe5500",
  //   600: "#cb4400",
  //   700: "#983300",
  //   800: "#662200",
  //   900: "#331100",
  // },
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      // palette values for light mode
      primary: {
        main: colors.secondary[500],
      },
      secondary: {
        main: colors.secondary[500],
      },
      neutral: {
        dark: colors.grey[700],
        main: colors.grey[500],
        light: colors.grey[100],
      },
      background: {
        default: "#fff",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
