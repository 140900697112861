import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Paper } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import axios from "axios";

const BASE_URL = "https://coldsnapapps.com";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    editable: true,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 14 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 31 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 31 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 11 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export default function DataTable() {
  const [products, setProducts] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/Product`, {
          headers: {
            CustomerId: "CA8500B6-888C-4F3F-B3C2-FF15B256F6D3",
            "X-API-KEY": "Test1234",
          },
        });
        console.log(response.data);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  //   useEffect(() => {
  //     // Make GET request for each ID to fetch individual data
  //     ids.forEach((id) => {
  //       axios
  //         .get(`${BASE_URL}/${id}`)
  //         .then((response) => {
  //           setData((prevData) => [...prevData, response.data]);
  //         })
  //         .catch((error) => {
  //           console.error(`Error fetching data for ID ${id}:`, error);
  //         });
  //     });
  //   }, [ids]);

  return (
    <Box sx={{ m: "20px", height: 400, width: 1 }}>
      <PageTitle title="Products" subtitle="View all of your products" />
      <Paper elevation={3}>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>

        {/* <Box
          sx={{
            height: 500,
            width: "100%",

            "& .textPrimary": {
              color: "text.primary",
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 10, pageSize: 50 },
              },
            }}
            rowsPerPageOptions={[10, 50, { value: -1, label: "All" }]}
          />
        </Box> */}
      </Paper>
    </Box>
  );
}
