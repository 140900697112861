import React from "react";
import { Grid, InputAdornment, Typography } from "@mui/material";
import FormikField from "../../../../FormikField";
import CustomAccordion from "../CustomAccordion";

const TimeBeforeRental = () => {
  return (
    <CustomAccordion title={"Rental Alert Window"}>
      <Grid container spacing={2}>
        <Grid sx={{ my: "20px" }} item xs={12}>
          <Typography textAlign="center" variant="h5" fontWeight="600">
            How far in advance can customers book your product?
          </Typography>
          <Typography textAlign="center" variant="h6" fontWeight="600">
            (0 means same day)
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <FormikField
            name="minTimeBeforeRental"
            isNumber={true}
            endAdornment={
              <InputAdornment position="end">day(s)</InputAdornment>
            }
            label="Minimum"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikField
            name="maxTimeBeforeRental"
            isNumber={true}
            endAdornment={
              <InputAdornment position="end">day(s)</InputAdornment>
            }
            label="Maximum"
          />
        </Grid>
      </Grid>
    </CustomAccordion>
  );
};

export default TimeBeforeRental;
