import React from "react";
import { Divider } from "@mui/material";
import { TypographyTitle } from "../styles";

import NumberOfDaysRange from "./NumberOfDaysRange";
import TimeBeforeRental from "./TimeBeforeRental";
import TimeBeforeAvailable from "./TimeBeforeAvailable";

const Availability = () => {
  return (
    <>
      <TypographyTitle variant="h3">Availability</TypographyTitle>

      <NumberOfDaysRange />
      <Divider sx={{ marginY: "20px" }} />

      <TimeBeforeRental />
      <Divider sx={{ marginY: "20px" }} />

      <TimeBeforeAvailable />
    </>
  );
};

export default Availability;

// const startDateOptions = [
//   {
//     id: 1,
//     title: "Right Away",
//     icon: <ElectricBoltIcon fontSize="large" />,
//   },
//   {
//     id: 2,
//     title: "Custom Date",
//     icon: <MoreTimeIcon fontSize="large" />,
//   },
// ];

// const endDateOptions = [
//   {
//     id: 1,
//     title: "Never",
//     icon: <AllInclusiveIcon fontSize="large" />,
//   },
//   {
//     id: 2,
//     title: "Custom Date",
//     icon: <MoreTimeIcon fontSize="large" />,
//   },
// ];
