import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import TimePickers from "./TimePickers";
import { convertTime } from "../../../../../utils";
import { tokens } from "../../../../../theme";
import CustomButton from "../../../../../common/CustomButton";

const WeekdaySelection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { values, setFieldValue } = useFormikContext();
  const [selectAll, setSelectAll] = React.useState(false);

  const handleDayClick = (index) => {
    setFieldValue(
      `daysAvailable[${index}].selected`,
      !values.daysAvailable[index].selected
    );

    setFieldValue(`daysAvailable[${index}].allDay`, true);
  };

  const handleSwitchChange = (index) => {
    setFieldValue(
      `daysAvailable[${index}].allDay`,
      !values.daysAvailable[index].allDay
    );
    setFieldValue(`daysAvailable[${index}].startTime`, convertTime("08:00 AM"));
    setFieldValue(`daysAvailable[${index}].startTimeFormal`, "8:00 AM");
    setFieldValue(`daysAvailable[${index}].endTime`, convertTime("08:00 PM"));
    setFieldValue(`daysAvailable[${index}].endTimeFormal`, "8:00 PM");
  };

  const updateAllDays = (checked) => {
    if (checked) {
      setSelectAll(true);
      const newDaysAvailable = [...values.daysAvailable];
      newDaysAvailable.forEach((day) => {
        day.selected = true;
        day.allDay = true;
      });
      setFieldValue("daysAvailable", newDaysAvailable);
    } else {
      setSelectAll(false);
      const newDaysAvailable = [...values.daysAvailable];
      newDaysAvailable.forEach(
        (day) => ((day.selected = false), (day.allDay = false))
      );
      setFieldValue("daysAvailable", newDaysAvailable);
    }
  };

  return (
    <Box>
      <Typography textAlign="center" mb="20px" variant="h4" fontWeight="800">
        Weekday Selection
      </Typography>
      <Typography textAlign="center" variant="h5" fontWeight="600" my="20px">
        Which days do you want to make your product available?
      </Typography>

      <FieldArray name="daysAvailable">
        {() => (
          <Box>
            <Box>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: ".8rem",
                    fontWeight: "600",
                    opacity: "0.9",
                  },
                }}
                label="Select All"
                control={
                  <Checkbox
                    color="secondary"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 24 },
                    }}
                    checked={selectAll}
                    onChange={(e) => updateAllDays(e.target.checked)}
                  />
                }
              />
            </Box>

            <Box>
              {values.daysAvailable.map(({ day }, index) => (
                <Box
                  key={index}
                  height={"80px"}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom="1px solid rgba(0, 0, 0, 0.12)"
                  flex={1}
                >
                  <CustomButton
                    width="8rem"
                    variant={
                      values.daysAvailable[index].selected
                        ? "contained"
                        : "outlined"
                    }
                    color="secondary"
                    onClick={() => handleDayClick(index)}
                  >
                    {day}
                  </CustomButton>

                  {values.daysAvailable[index].selected && (
                    <Box flex={1} ml="2rem">
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={() => handleSwitchChange(index)}
                            checked={values.daysAvailable[index].allDay}
                            color="secondary"
                          />
                        }
                        label="All Day"
                      />
                    </Box>
                  )}

                  {values.daysAvailable[index].selected &&
                    !values.daysAvailable[index].allDay && (
                      <TimePickers index={index} />
                    )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};

export default WeekdaySelection;
