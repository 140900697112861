export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function convertTime(timeString) {
  // Parse the time string
  const [time, period] = timeString.split(" ");
  const [hours, minutes] = time.split(":").map(Number);
  const timeZoneOffsetMinutes = new Date().getTimezoneOffset();

  // Convert to 24-hour format
  let militaryHours = hours;
  if (period === "PM" && hours !== 12) {
    militaryHours += 12;
  } else if (period === "AM" && hours === 12) {
    militaryHours = 0;
  }

  // Get local time zone offset
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset();

  // Apply time zone offset
  const utcTime = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    militaryHours,
    minutes
  );
  const adjustedTime = new Date(utcTime + timeZoneOffset * 60 * 1000);

  // Format the military time
  const formattedMilitaryTime = `${adjustedTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${adjustedTime
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  return `${formattedMilitaryTime}:00`;
  // return `${formattedMilitaryTime}T${timeZoneOffsetMinutes}`;
}

// Function to generate an array of times every 15 minutes
export const generateTimes = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = (hour % 12 || 12).toString(); // Modified formatting for single-digit hours
      const formattedMinute = minute.toString().padStart(2, "0");
      const period = hour < 12 ? "AM" : "PM";
      times.push(`${formattedHour}:${formattedMinute} ${period}`);
    }
  }
  return times;
};

export const dateLabel = (date) => {
  switch (date) {
    case 0:
      return "Same Day";
    case 1:
      return "Next Day";
    default:
      return `${date} days`;
  }
};

export const displayWeekday = (day) => {
  if (day.allDay) {
    return "All Day";
  } else {
    return `${day.startTimeFormal} - ${day.endTimeFormal}`;
  }
};

export const datesFormatter = (dates) => {
  const formattedDates = dates
    .map((date) => {
      if (date.selected) {
        return {
          dayOfWeek: dayOfWeek(date.day),
          startTime: date.allDay ? "01:00:00" : date.startTime,
          endTime: date.allDay ? "23:00:00" : date.endTime,
        };
      } else {
        return null;
      }
    })
    .filter((date) => date !== null);
  return formattedDates;
};

// write a switch case statement that takes in a day of the week and returns 0 for sunday, 1 for monday, etc.
export const dayOfWeek = (day) => {
  switch (day) {
    case "Sunday":
      return 0;
    case "Monday":
      return 1;
    case "Tuesday":
      return 2;
    case "Wednesday":
      return 3;
    case "Thursday":
      return 4;
    case "Friday":
      return 5;
    case "Saturday":
      return 6;
    default:
      return null;
  }
};

// write a local stoage function that sets and gets the success of creating a calendar
export const setCalendarSuccess = (success) => {
  localStorage.setItem("calendarSuccess", success);
};

export const getCalendarSuccess = () => {
  return localStorage.getItem("calendarSuccess");
};
