import {
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";

const GroupButtons = ({ values, state, handleOnChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const CustomToggleButton = styled(ToggleButton)(({ theme }) => {
    return {
      "&.MuiToggleButtonGroup-grouped": {
        borderRadius: "4px !important",
        border: `1px solid ${colors.secondary[500]} !important`,
        width: "50%",
        gap: "10px",
        color: colors.secondary[500],
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: colors.secondary[500],
      },

      color: "white",
      padding: "0 20px",
      height: "50px",
    };
  });
  return (
    <ToggleButtonGroup
      value={state}
      color="secondary"
      exclusive
      onChange={handleOnChange}
      style={{
        gap: "20px",
        width: "100%",
      }}
    >
      {values.map((value, index) => (
        <CustomToggleButton key={index} value={value.value}>
          {value.icon}
          <Typography fontSize="16px" fontWeight="600">
            {value.title}
          </Typography>
        </CustomToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default GroupButtons;
