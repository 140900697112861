import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GroupButtons from "../../../../GroupButtons";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HeightIcon from "@mui/icons-material/Height";
import { TypographyItemName, TypographyTitle } from "../styles";
import { useFormikContext } from "formik";
import FormikField from "../../../../FormikField";
import ArrowTooltip from "../../../../../common/ArrowTooltip";

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const todaysDate = `${year}-${month}-${day}`;

const BasicInfo = () => {
  const { values, setFieldValue } = useFormikContext();
  const [startDate, setStartDate] = useState(dayjs(todaysDate));
  const [endDate, setEndDate] = useState(null);

  const handleStartDate = (date) => {
    setFieldValue("startDate", date.toISOString());
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setFieldValue("endDate", date.toISOString());
    setEndDate(date);
  };

  return (
    <>
      <TypographyTitle variant="h3">Basic Info</TypographyTitle>

      <Box p={2}>
        <TypographyItemName variant="h5">Calendar Name</TypographyItemName>
        <FormikField name={"calendarName"} />
      </Box>

      <Divider sx={{ marginY: "20px" }} />

      <Box p={2}>
        <TypographyItemName variant="h5">Booking Type</TypographyItemName>
        <GroupButtons
          values={bookingTypes}
          state={values.bookingType}
          handleOnChange={(e, value) => {
            if (values.bookingType !== value && value) {
              return setFieldValue("bookingType", value);
            }
          }}
        />
      </Box>

      <Divider sx={{ marginY: "20px" }} />

      <Box p={2}>
        <Box display="flex" gap="10px">
          <TypographyItemName variant="h5">
            When will Calendar Start/End?
          </TypographyItemName>
          <ArrowTooltip
            info="Start date is when the calendar becomes active. 
            The end date is when it will be disabled. 
            By not selecting end date, the calendar will be active indefinitely."
          />
        </Box>

        <Box display="flex" mt="20px" justifyContent="space-between">
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DesktopDatePicker
                  value={startDate}
                  onChange={(date) => handleStartDate(date)}
                  label="Select Start Date"
                  defaultValue={dayjs(todaysDate)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Divider
            orientation="vertical"
            sx={{ marginRight: "0px" }}
            flexItem
          />

          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  value={endDate}
                  onChange={(date) => handleEndDate(date)}
                  label="Select End Date"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt="10px">
          <Typography variant="body2" color="text.secondary">
            End Date is optional
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default BasicInfo;

const bookingTypes = [
  {
    id: 1,
    title: "Single Day Rental",
    value: "singleDay",
    icon: <AutorenewIcon fontSize="large" />,
  },
  {
    id: 2,
    title: "Multi-Day Rental",
    value: "multiDay",
    icon: (
      <HeightIcon
        fontSize="large"
        sx={{
          transform: "rotate(90deg)",
        }}
      />
    ),
  },
];
