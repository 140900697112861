import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button, useTheme } from "@mui/material";
import { tokens } from "../theme";

const CustomButton = ({
  children,
  width,
  color,
  variant,
  onClick,
  disabled = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const buttonColor =
    color === "secondary" ? colors.secondary[500] : colors.primary[500];

  const buttonHoverDark =
    color === "secondary" ? colors.secondary[400] : colors.primary[400];

  const buttonHoverLight = "#f7f7f7";

  const Buttons = styled(Button)(() => {
    return {
      padding: "5px 20px",
      height: "50px",
      fontSize: "16px",
      fontWeight: "600",
      backgroundColor: variant === "contained" ? buttonColor : "transparent",
      width: width ? width : "max-content",
      border: variant === "outlined" ? `1px solid ${buttonColor}` : "none",
      color: variant === "contained" ? colors.text : buttonColor,

      "&:hover": {
        backgroundColor:
          variant === "contained" ? buttonHoverDark : buttonHoverLight,
        border: variant === "outlined" ? `1px solid ${buttonColor}` : "none",
      },
    };
  });

  return (
    <Buttons
      onClick={onClick}
      disabled={disabled}
      variant={variant || "outlined"}
    >
      {children}
    </Buttons>
  );
};

export default CustomButton;
