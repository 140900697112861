import { Box } from "@mui/material";
import React from "react";
import PageTitle from "../PageTitle";

const Settings = () => {
  return (
    <Box m="20px">
      <PageTitle title="Settings" subtitle="View and manage your settings" />
    </Box>
  );
};

export default Settings;
