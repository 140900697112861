import { styled, Typography } from "@mui/material";

export const TypographyTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  marginBottom: "20px",
  display: "flex",
  justifyContent: "center",
}));

export const TypographyItemName = styled(Typography)(() => ({
  fontWeight: "600",
  marginBottom: "10px",
  opacity: "0.7",
}));
