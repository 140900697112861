import React from "react";
import { Formik } from "formik";
import { Box } from "@mui/material";
import { FormStepper } from "../FormStepper";

const FormList = () => {
  return (
    <Box width="50rem" margin="auto">
      <Formik
        initialValues={{
          calendarName: "",
          // product: null,
          bookingType: "singleDay",
          daysAvailable: [
            {
              day: "Sunday",
              startTime: "",
              startTimeFormal: "",
              endTime: "",
              endTimeFormal: "",
              selected: false,
              allDay: false,
            },
            {
              day: "Monday",
              startTime: "",
              startTimeFormal: "",
              endTime: "",
              endTimeFormal: "",
              selected: false,
              allDay: false,
            },
            {
              day: "Tuesday",
              startTime: "",
              startTimeFormal: "",
              endTime: "",
              endTimeFormal: "",
              selected: false,
              allDay: false,
            },
            {
              day: "Wednesday",
              startTime: "",
              startTimeFormal: "",
              endTime: "",
              endTimeFormal: "",
              selected: false,
              allDay: false,
            },
            {
              day: "Thursday",
              startTime: "",
              startTimeFormal: "",
              endTime: "",
              endTimeFormal: "",
              selected: false,
              allDay: false,
            },
            {
              day: "Friday",
              startTime: "",
              startTimeFormal: "",
              endTime: "",
              endTimeFormal: "",
              selected: false,
              allDay: false,
            },
            {
              day: "Saturday",
              startTime: "",
              startTimeFormal: "",
              endTime: "",
              endTimeFormal: "",
              selected: false,
              allDay: false,
            },
          ],
          startDate: 1,
          endDate: 365,
          maximumDateRange: 50,
          minimumDateRange: 1,
          minTimeBeforeRental: "01:00:00",
          maxTimeBeforeRental: "02:00:00",
          timeBeforeAvailableAgain: 0,
          // multiDayIsEnabled: false,
          // multiDayDiscounts: [
          //   { quantityOfDays: "", discount: "", type: "percent" },
          // ],
          // depositIsEnabled: false,
          // deposit: { label: "", deposit: "" },
          // upFrontCostIsEnabled: false,
          // upFrontCost: "",
          // specialDatesIsEnabled: false,
          // specialDates: [
          // { date: "", discount: "", minimum: "", type: "percent" },
          // ],
          // spendAndSaveIsEnabled: false,
          // spendAndSave: [{ spend: "", save: "" }],
          // links: [linksGroup],
        }}
        onSubmit={async (values, actions) => {
          console.log("values:", values);
        }}
      >
        {({ values }) => <FormStepper />}
      </Formik>
    </Box>
  );
};

export default FormList;
