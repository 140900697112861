import React, { useEffect } from "react";
import { Box } from "@mui/material";
import PageTitle from "./PageTitle";
import axios from "axios";

const BASE_URL = "https://coldsnapapps.com";

const Calendars = () => {
  const [calendars, setCalendars] = React.useState([]);

  useEffect(() => {
    const fetchCalendars = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/Calendar?pageNumber=1&rowsPerPage=5`,
          {
            headers: {
              CustomerId: "CA8500B6-888C-4F3F-B3C2-FF15B256F6D3",
              "X-API-KEY": "Test1234",
            },
          }
        );
        console.log(response.data);
        setCalendars(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCalendars();
  }, []);

  return (
    <Box m="20px">
      <PageTitle
        title="Calendars"
        subtitle="View the all of the calendars you have created."
      />

      <Box>
        {calendars.length === 0 ? (
          <p>Sorry. No calendars found.</p>
        ) : (
          <ul>
            {calendars.map((calendar, index) => (
              <li key={calendar.id}>Calendar #{index + 1}</li>
            ))}
          </ul>
        )}
        {/* <div>
          <h1>IDs:</h1>
          <ul>
            {ids.map((id) => (
              <li key={id}>{id}</li>
            ))}
          </ul>
          <h1>Data:</h1>
          <ul>
            {data.map((item, index) => (
              <li key={index}>{JSON.stringify(item)}</li>
            ))}
          </ul>
        </div> */}
      </Box>
    </Box>
  );
};

export default Calendars;
