import { Box, useTheme, Paper, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import StatBox from "../StatBox";
import { tokens } from "../../theme";
import StoreIcon from "@mui/icons-material/Store";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupsIcon from "@mui/icons-material/Groups";
import BarChart from "../BarChart";
import AlignItemsList from "../RecentRentals";
import {
  currencyFormatter,
  getCalendarSuccess,
  setCalendarSuccess,
} from "../../utils";
import CustomToast from "../../common/CustomToast";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const success = getCalendarSuccess();
    if (success === "true") {
      setShowToast(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCalendarSuccess(false);
      setShowToast(false);
    }, 3000);
  }, [showToast]);

  return (
    <Box m="20px">
      <PageTitle title="Dashboard" subtitle="Welcome to your dashboard" />

      {/* Row 1 */}
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Paper elevation={3}>
            <StatBox
              title="Total Bookings This Month"
              difference="+20 from last month"
              value="8"
              icon={
                <StoreIcon sx={{ color: colors.grey[700], fontSize: "26px" }} />
              }
            />
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper elevation={3}>
            <StatBox
              title="Monthly Revenue"
              difference="+9% from last month"
              value={currencyFormatter.format(442.52)}
              icon={
                <AttachMoneyIcon
                  sx={{ color: colors.grey[700], fontSize: "26px" }}
                />
              }
            />
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper elevation={3}>
            <StatBox
              title="Yearly Revenue"
              difference="+12 from last year"
              value={currencyFormatter.format(8042.52)}
              icon={
                <AttachMoneyIcon
                  sx={{ color: colors.grey[700], fontSize: "26px" }}
                />
              }
            />
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper elevation={3}>
            <StatBox
              title="Total Customers"
              difference="+14 from last month"
              value="74"
              icon={
                <GroupsIcon
                  sx={{ color: colors.grey[700], fontSize: "26px" }}
                />
              }
            />
          </Paper>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: "30px", height: "450px" }}>
            <Typography variant="h4" fontWeight="bold">
              Overview
            </Typography>
            <BarChart />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: "30px", height: "450px" }}>
            <Typography variant="h4" fontWeight="bold">
              Recent Rentals
            </Typography>
            <AlignItemsList />
          </Paper>
        </Grid>
      </Grid>
      <CustomToast
        open={showToast}
        message="Calendar created successfully"
        severity="success"
        onClose={() => setShowToast(false)}
      />
    </Box>
  );
};

export default Dashboard;
