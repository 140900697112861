import { Box } from "@mui/material";
import PageTitle from "../../PageTitle";
import FormList from "./Steps/FormList";

// const steps = [
//   { label: "Basic Info", component: BasicInfo },
//   { label: "Availablity", component: Availability },
//   { label: "Special Pricing", component: SpecialPricing },
//   { label: "Payment Options", component: BasicInfo },
//   { label: "Review", component: BasicInfo },
// ];

const CreateCalendar = () => {
  return (
    <Box>
      <Box width="80%" margin="auto" marginTop="30px">
        <PageTitle
          title="Create a Calendar Template"
          subtitle="Add a new product you'd link to rent"
        />
      </Box>
      <FormList />
    </Box>
  );
};

export default CreateCalendar;
