import { TextField } from "@mui/material";
import { Field } from "formik";
import React from "react";

const FormikField = ({ name, label, isNumber, endAdornment }) => {
  return (
    <Field name={name}>
      {({ field, /* { name, value, onChange, onBlur } */ form, meta }) => (
        <TextField
          inputProps={{
            style: {
              MozAppearance: "textfield",
              WebkitAppearance: "none",
              appearance: "none",
            },
          }}
          InputProps={{
            endAdornment: endAdornment,
          }}
          label={label}
          {...field}
          fullWidth
          type={isNumber ? "number" : "text"}
          variant="outlined"
          sx={{
            "&.MuiInputBase-root-active MuiOutlinedInput-root": {
              borderColor: "red",
            },

            // "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "green"
            // },
            // "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "red"
            // },
            // "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "purple"
            // },
          }}
        />
      )}
    </Field>
  );
};

export default FormikField;
