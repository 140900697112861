import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";

const data = [
  {
    country: "Jan",
    sandwich: 131,
  },
  {
    country: "Feb",
    sandwich: 14,
  },
  {
    country: "Mar",
    sandwich: 200,
  },
  {
    country: "Apr",
    sandwich: 23,
  },
  {
    country: "May",
    sandwich: 147,
  },
  {
    country: "Jun",
    sandwich: 32,
  },
  {
    country: "Jul",
    sandwich: 48,
  },
  {
    country: "Aug",
    sandwich: 200,
  },
  {
    country: "Sep",
    sandwich: 23,
  },
  {
    country: "Oct",
    sandwich: 147,
  },
  {
    country: "Nov",
    sandwich: 32,
  },
  {
    country: "Dec",
    sandwich: 48,
  },
];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveBar
      data={data}
      theme={{
        // added
        axis: {
          ticks: {
            line: {
              stroke: "transparent",
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        tooltip: {
          container: {
            color: colors.secondary[500],
          },
        },
      }}
      borderRadius="8px"
      keys={["sandwich"]}
      indexBy="country"
      groupMode="grouped"
      margin={{ top: 30, right: 10, bottom: 30, left: 30 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={[colors.secondary[500]]}
      axisTop={null}
      axisRight={null}
      // axisBottom={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legendPosition: "middle",
      //   legendOffset: 32,
      // }}
      // axisLeft={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legendPosition: "middle",
      //   legendOffset: -40,
      // }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
    />
  );
};

export default MyResponsiveBar;
