import { Box } from "@mui/material";
import React from "react";
import PageTitle from "../PageTitle";

const RentalHistory = () => {
  return (
    <Box m="20px">
      <PageTitle
        title="Rental History"
        subtitle="View the rentals history for your products"
      />
    </Box>
  );
};

export default RentalHistory;
