import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Box,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { tokens } from "../theme";
import TodayIcon from "@mui/icons-material/Today";
import InventoryIcon from "@mui/icons-material/Inventory";
import HistoryIcon from "@mui/icons-material/History";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const ColorButton = styled(Button)(() => ({
    color: "white !important",
    fontSize: "14px",
    fontWeight: "800",
    backgroundColor: colors.primary[500],
    padding: "10px 10px",
    "&:hover": {
      backgroundColor: colors.primary[400],
    },
  }));

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `#F2F2F2 !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.grey[300]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `'${colors.grey[100]}' !important`,
          borderLeft: `3px solid ${colors.grey[100]} !important`,
          background:
            theme.palette.mode === "dark"
              ? `rgb(224,224,224, 0.1) !important`
              : `rgb(20,20,20, 0.1) !important`,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={
              isCollapsed ? (
                <TodayIcon sx={{ color: colors.grey[100], fontSize: "40px" }} />
              ) : undefined
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box display="flex" alignItems="center" ml="15px">
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <TodayIcon
                    sx={{ color: colors.grey[100], fontSize: "40px" }}
                  />
                </IconButton>
                <Typography variant="h5" fontWeight="bold">
                  ColdSnap
                </Typography>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              ></Box>
              <Box textAlign="center">
                <Typography variant="h5" color={colors.grey[500]}>
                  Welcome back,
                </Typography>
                <Typography
                  variant="h3"
                  color={colors.grey[300]}
                  fontWeight="700"
                >
                  Alicia Holm!
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Products"
              to="/products"
              icon={<InventoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Rental History"
              to="/rentals"
              icon={<HistoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendars"
              to="/all-calendars"
              icon={<CalendarMonthIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Settings"
              to="/settings"
              icon={<SettingsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          <Box mt={10} display="flex" justifyContent="center">
            <ColorButton
              onClick={(e) => {
                e.preventDefault();
                setSelected("Create Calendar Template");
              }}
              // href="/link-product"
              variant="contained"
            >
              <Link to="/link-product" />
              {isCollapsed ? (
                <AddCircleOutlineIcon color="primary" />
              ) : (
                "Create Calendar Template"
              )}
            </ColorButton>
            {/* <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlined />
              ) : (
                <LightModeOutlined />
              )}
            </IconButton> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
